'use strict'

const routes = [
  {
    path: '/cadastro-do-usuario',
    name: 'UserRegistration',
    meta: {
      title: 'Cadastro do usuário',
      step: 1
    },
    component: () => import(/* webpackChunkName: "user-registration" */'views/UserRegistration')
  },
  {
    path: '/cadastro-da-empresa',
    name: 'CompanyRegistration',
    meta: {
      title: 'Cadastro da empresa',
      step: 2
    },
    component: () => import(/* webpackChunkName: "company-registration" */'views/CompanyRegistration')
  },
  {
    path: '/cadastro-negado',
    name: 'RegistrationDenied',
    meta: {
      title: 'Cartão negado',
      step: 2
    },
    component: () => import(/* webpackChunkName: "registration-denied" */'views/RegistrationDenied')
  },
  {
    path: '/forma-de-pagamento',
    name: 'PaymentMethod',
    meta: {
      title: 'Forma de pagamento',
      step: 3
    },
    component: () => import(/* webpackChunkName: "payment-method" */'views/PaymentMethod')
  },
  {
    path: '/cartao-negado',
    name: 'CreditCardDeniedAttempts',
    meta: {
      title: 'Cartão negado',
      step: 3
    },
    component: () => import(/* webpackChunkName: "credit-card-denied-attempts" */'views/CreditCardDeniedAttempts')
  },
  {
    path: '/cartao-negado-pelo-adquirente',
    name: 'CreditCardDenied',
    meta: {
      title: 'Cartão negado pelo adquirente',
      step: 3
    },
    component: () => import(/* webpackChunkName: "credit-card-denied" */'views/CreditCardDenied')
  },
  {
    path: '/cadastro-recusado',
    name: 'RegistrationRefused',
    meta: {
      title: 'Cadastro negado',
      step: 3
    },
    component: () => import(/* webpackChunkName: "registration-refused" */'views/RegistrationDenied')
  },
  {
    path: '/cadastro-submetido',
    name: 'RegistrationSubmitted',
    meta: {
      title: 'Cadastro submetido',
      step: 4
    },
    component: () => import(/* webpackChunkName: "registration-submitted" */'views/RegistrationSubmitted')
  },
  {
    path: '/cadastro-confirmado/:pid?',
    name: 'ConfirmedRegistration',
    meta: {
      title: 'Cadastro confirmado',
      step: 5
    },
    component: () => import(/* webpackChunkName: "confirmed-registration" */'views/ConfirmedRegistration'),
    beforeEnter (to, from, next) {
      return !(to.params && to.params.pid)
        ? next({ name: 'UserRegistration' })
        : next()
    }
  },
  // redirects
  {
    path: '/',
    redirect: '/cadastro-do-usuario'
  },
  {
    path: '/dados-da-empresa',
    redirect: '/cadastro-do-usuario'
  },
  {
    path: '*',
    redirect: '/cadastro-do-usuario'
  }
]

export default routes
