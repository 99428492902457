'use strict'

import { apiInstance } from './index'
import { SESSION_SOURCE } from 'utils/constants'
const prospect = {
  step1: {
    createSimpleProspect (data) {
      return apiInstance({
        method: 'POST',
        url: '/prospect/contact',
        data: { ...data, source: sessionStorage.getItem(SESSION_SOURCE) }
      })
    },

    updateProspect (data) {
      return apiInstance({
        method: 'PUT',
        url: '/prospect/contact',
        data: { ...data, source: sessionStorage.getItem(SESSION_SOURCE) }
      })
    }
  },

  step2: {
    createCompany (companyId, data) {
      return apiInstance({
        method: 'POST',
        url: `/prospect/company/${companyId}`,
        data
      })
    }
  },

  step3: {
    updateDealContactByCorporateId (companyId) {
      return apiInstance({
        method: 'PUT',
        url: `/prospect/company/${companyId}`
      })
    }
  },

  step4: {
    crossCoreValidation (companyId, data) {
      return apiInstance({
        method: 'PUT',
        url: `/prospect/company/crosscore/${companyId}`,
        data
      })
    }
  },

  step5: {
    creditValidation (companyId) {
      return apiInstance({
        method: 'PUT',
        url: `/prospect/company/credit/${companyId}`
      })
    }
  },

  step6: {
    billPayment (data) {
      return apiInstance({
        method: 'PUT',
        url: '/prospect/payment/bill',
        data
      })
    },

    creditCardPayment (data) {
      return apiInstance({
        method: 'PUT',
        url: '/prospect/payment/creditcard',
        data
      })
    }
  },

  step7: {
    enableCompany (pid) {
      return apiInstance({
        method: 'POST',
        url: `/prospect/confirmation/${pid}`
      })
    }
  }
}

export default prospect
