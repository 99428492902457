'use strict'

import Cookie from 'utils/Cookie'

export const SESSION_PROSPECT = concatEnv('99_SMB_PROSPECT')
export const SESSION_SOURCE = concatEnv('99_SOURCE')
export const SESSION_CURRENT_STEP = concatEnv('99_SMB_CURRENT_STEP')
export const SESSION_CREDIT_CARD_ATTEMPTS = concatEnv('99_SMB_CREDIT_CARD_ATTEMPTS')
export const SESSION_PROSPECT_EXPIRES = 60 * 60 * 24 * 7 // seconds * minutes * hours * days
export const GOOGLE_ANALYTICS_ID = 'UA-32120185-34'
export const STATUS_IS_LOADING = 1
export const STATUS_SUCCESS = 2
export const STATUS_FAILED = 3
export const HUBSPOT = {
  PORTAL_ID: 2206199,
  FORM_GUID: '3e63d0b1-7ec7-4239-b938-9c5ba36b05fd',
  HUTK: Cookie.get('hubspotutk')
}

function concatEnv (str) {
  const env = process.env.NODE_ENV.toUpperCase()
  const isProduction = env === 'production'
  return !isProduction ? `${str}_${env}` : str
}
