'use strict'

const state = {
  color: null,
  message: '',
  timeout: -1,
  isVisible: false
}

const actions = {
  show ({ commit }, payload) {
    commit('clear')
    setTimeout(() => { commit('show', payload) }, 100)
  },

  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  show (state, { type, message, timeout }) {
    state.color = type
    state.message = message
    state.timeout = timeout || 8000
    state.isVisible = true
  },

  clear (state) {
    state.color = null
    state.message = ''
    state.timeout = -1
    state.isVisible = false
  }
}

const snackbar = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default snackbar
