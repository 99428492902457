'use strict'

import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App'
import router from './router'
import store from './store'
import services from './services'
import SerasaCollector from './libs/serasa/SerasaCollector'
import GTMDataLayer from './libs/GTMDataLayer'
import VeeValidate from 'vee-validate'
import validationMessagesPtBR from 'vee-validate/dist/locale/pt_BR'
import validationMessagesEn from 'vee-validate/dist/locale/en'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics'
import { GOOGLE_ANALYTICS_ID, SESSION_SOURCE } from 'utils/constants'
import Debug from './components/Debug'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import 'common/omega'
import qs from 'qs'

sessionStorage.setItem(SESSION_SOURCE, qs.parse(window.location.search, { ignoreQueryPrefix: true }).source || '')
Object.keys(services).forEach(service => Vue.use(services[service]))

Vue.use(SerasaCollector)
Vue.use(GTMDataLayer)
Vue.use(VeeValidate, {
  events: 'change|blur|submit',
  locale: 'pt_BR',
  dictionary: {
    pt_BR: validationMessagesPtBR,
    en: validationMessagesEn
  }
})

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

Vue.use(VueAnalytics, {
  id: GOOGLE_ANALYTICS_ID,
  checkDuplicatedScript: false,
  debug: {
    enabled: false,
    trace: false,
    sendHitTask: process.env.NODE_ENV === 'production'
  }
})

Vue.component('Debug', Debug)

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: 'https://f99512ea39ad4f57bbdfc1f1f9f0a979@sentry-us.didiglobal.com/78',
  integrations: [
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 1.0,
  environment: `${process.env.NODE_ENV}` || 'production',
  release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}` || 'b2b-onboarding@2.0.0'
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
