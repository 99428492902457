'use strict'

class GTMDataLayer {
  send (payload, debug = false) {
    const dataLayer = {
      pageName: document.title,
      pageUrl: window.location.href,
      ...payload
    }

    if (process.env.NODE_ENV === 'production') {
      return window.dataLayer.push(dataLayer)
    }

    if (debug) {
      /* eslint-disable */
      console.groupCollapsed('GTM Push Data Layer')
      console.table(dataLayer)
      console.groupEnd()
    }
  }
}

export default {
  install (Vue) {
    Vue.$GTMDataLayer = new GTMDataLayer()
    Vue.prototype.$GTMDataLayer = new GTMDataLayer()
  }
}
