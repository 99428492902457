<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="props.timeout"
    :color="props.color"
    :value="snackbar"
    fixed
    left
    bottom
  >
    <span v-if="props.message" v-html="props.message" />
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Snackbar',
  computed: {
    ...mapState({
      props: ({ snackbar }) => snackbar
    }),

    snackbar: {
      set () {
        this.clearAlert()
      },

      get () {
        return this.props.isVisible
      }
    }
  },

  methods: {
    ...mapActions({
      clearAlert: 'snackbar/clear'
    })
  }
}
</script>

<style lang="scss">
.v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
