'use strict'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#FF8200',
        error: '#e20d0d'
      }
    }
  },
  lang: {
    locales: { pt },
    current: 'pt'
  }
})
