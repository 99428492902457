'use strict'

import './6.9.0/baa10e627a58'

class SerasaCollector {
  constructor () {
    this._collectorVersion = '6.9.0'
    this._$hdimInput = document.getElementById('4b3e')
    this._$jscInput = document.getElementById('1a3d')
    this.renderInputs()
  }

  renderHdimInput () {
    // HDM data
    if (!this._$hdimInput) {
      const $hdimInput = document.createElement('input')
      $hdimInput.type = 'hidden'
      $hdimInput.id = '4b3e'
      $hdimInput.name = '4b3e'
      document.body.appendChild($hdimInput)
    }
  }

  renderJscInput () {
    // Payload
    if (!this._$jscInput) {
      const $jscInput = document.createElement('input')
      $jscInput.type = 'hidden'
      $jscInput.id = '1a3d'
      $jscInput.name = '1a3d'
      document.body.appendChild($jscInput)
    }
  }

  renderInputs () {
    this.renderHdimInput()
    this.renderJscInput()
  }

  init () {
    window.arj.init(null)
  }

  submit () {
    window.arj.val('1a3d')

    return {
      jsc: this._$jscInput.value,
      hdim: this._$hdimInput.value
    }
  }
}

export default {
  install (Vue) {
    Vue.$sc = new SerasaCollector()
    Vue.prototype.$sc = new SerasaCollector()
  }
}
