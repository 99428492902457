'use strict'
/**
 * 开发测试用的api
 * author: jingqinyang
 */
import { apiInstance } from './index'

const apiTest = {
  greyTest (data) {
    return apiInstance({
      method: 'GET',
      url: '/apiTest1',
      data
    })
  }
}

export default apiTest
