'use strict'

const state = {
  fixed: false
}

const actions = {
  toggle ({ commit }, boolean) {
    commit('toggle', boolean)
  }
}

const mutations = {
  toggle (state, boolean) {
    state.fixed = boolean
  }
}

const formFooter = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default formFooter
