'use strict'

import Cookie from 'utils/Cookie'
import { SESSION_CURRENT_STEP, SESSION_CREDIT_CARD_ATTEMPTS, SESSION_PROSPECT } from 'utils/constants'

const state = {
  currentStep: Number(Cookie.get(SESSION_CURRENT_STEP)) || 1,
  creditCardAttempts: JSON.parse(Cookie.get(SESSION_CREDIT_CARD_ATTEMPTS)) || 0,
  data: JSON.parse(Cookie.get(SESSION_PROSPECT)) || {}
}

const actions = {
  set ({ commit }, payload) {
    commit('set', payload)
  },

  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  set (state, { data, currentStep, creditCardAttempts }) {
    state.currentStep = currentStep
    state.creditCardAttempts = creditCardAttempts
    state.data = data
  },

  clear (state) {
    state.currentStep = 1
    state.creditCardAttempts = 0
    state.data = {}
  }
}

const prospect = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default prospect
