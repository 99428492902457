import OmegaTracker from '@didi/omega-tracker'

const config = {
  appKey: 'omega86d627bc1d',
  uploadHost: process.env.NODE_ENV === 'production' ? 'omgup.didiglobal.com' : '',
  autoClick: false // 全局点击事件监控，如不需要 建议关闭
}
const omegaInstance = OmegaTracker.getTracker(config)

export class Omega {
  static getBasicAttrs () {
    return {}
  }

  /**
   * 初始化
   */
  static init (userId) {
    console.log(`omega init: ${userId}`)
    omegaInstance.setUserName(userId)
  }

  /**
   * 事件埋点
   */
  static trackEvent (eventId, attrs = {}) {
    const basicAttrs = Omega.getBasicAttrs() || {}
    omegaInstance.trackEvent(eventId, '', { ...basicAttrs, ...attrs })
  }

  /**
   * 错误上报
   */
  static tracekError (name, code, msg, attrs) {
    omegaInstance.trackError(name, code, msg, attrs)
  }
}
