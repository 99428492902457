<template>
  <v-app :class="{ 'v-application__fixed-footer': fixedFormFooter }">
    <router-view />
    <Snackbar />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Snackbar from 'components/Snackbar'

export default {
  name: 'App',
  metaInfo: {
    title: '99 Empresas - App de transporte para sua empresa'
  },
  computed: {
    ...mapState({
      fixedFormFooter: ({ formFooter }) => formFooter.fixed
    })
  },
  components: {
    Snackbar
  },
  methods: {
    checkHostName () {
      const hostname = location.hostname
      const port = location.port

      if (process.env.NODE_ENV === 'development' && hostname !== 'developer') {
        location.href = `http://developer:${port}`
      }
    }
  },
  beforeMount () {
    // this.checkHostName()
  },
  created () {
    this.$sc.init()
  },
  mounted () {
    console.log('VUE_APP_COMMIT_VERSION: ', process.env.VUE_APP_COMMIT_VERSION)
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/index';
</style>
