'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import formFooter from './formFooter'
import prospect from './prospect'
import snackbar from './snackbar'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    formFooter,
    prospect,
    snackbar
  }
})
