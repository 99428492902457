'use strict'

import moment from 'moment'

const Cookie = {
  /**
   * Set a new cookie with a expiration time from now
   *
   * @param {string} name : Cookie name
   * @param {string} value : Cookie value
   * @param {integer} expires : Cookie expiration time, in seconds from right now. Ex: (60 * 60) || 3600 = 1hr
   * @returns {void}
   */
  set (name, value, expires) {
    const d = moment().add(expires, 's').toDate()
    const cExpires = 'expires=' + d.toUTCString()
    document.cookie = name + '=' + value + ';' + cExpires + ';path=/'
  },

  /**
   * Get a cookie by name
   * @param {string} cName : Cookie name
   * @returns {string or null} cookie content or null if not found
   */
  get (cName) {
    const name = cName + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return null
  }
}

export default Cookie
