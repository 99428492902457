'use strict'

import { apiInstance } from './index'

const address = {
  getAddress (cep) {
    return apiInstance({
      method: 'GET',
      url: `/address/${cep}`,
      timeout: 8000
    })
  }
}

export default address
