'use strict'

import axios from 'axios'
import prospect from './prospect'
import address from './address'
import apiTest from './apiTest'
// const { VUE_APP_API_URL, NODE_ENV } = process.env
const { VUE_APP_API_URL } = process.env

export const apiInstance = axios.create({
  baseURL: VUE_APP_API_URL,
  timeout: 30000
  // withCredentials: NODE_ENV === 'production'
})
/**
 * 灰度开关处理处理

function removeStart (str) {
  return str.startsWith('/') ? str : '/' + str // 字符串不是以/开头，加上/用于url拼接
}

function beforeDealGrayUrl (config) {
  config.baseURL = process.env.VUE_APP_API_URL
  if (window.__grayConfig && window.__grayConfig.base_url) {
    const fetchUrl = 'b2b-service-onboarding-app/'
    const baseUrl = window.__grayConfig.base_url
    const needForwardUrl = window.__grayConfig.need_forward_url || []
    const rewriteUrls = window.__grayConfig.rewrite || {}
    const findSurl = needForwardUrl.find(surl => fetchUrl.startsWith(surl) || fetchUrl.startsWith(`/${surl}`))
    // eslint-disable-next-line no-unused-expressions
    const rewreitePath = Object.keys(rewriteUrls).find(itemKey => fetchUrl.startsWith(itemKey) || fetchUrl.startsWith(`/${itemKey}`))
    if (findSurl) {
      if (rewreitePath) config.baseURL = `${baseUrl}${removeStart(rewriteUrls[rewreitePath])}`
      else config.baseURL = `${baseUrl}${removeStart(fetchUrl)}`
    } else {
      config.baseURL = process.env.VUE_APP_API_URL
    }
  }
}
*/

apiInstance.interceptors.request.use(function (config) {
  // console.info('灰度关', config)
  // beforeDealGrayUrl(config)
  // console.info('灰度开', config)
  return config
}, function (error) {
  return Promise.reject(error)
})

const apiService = {
  ...prospect,
  ...address,
  ...apiTest
}

export default {
  install (Vue) {
    Vue.$apiService = apiService
    Vue.prototype.$apiService = apiService
  }
}
