'use strict'

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

function navigationGuards (to, from, next) {
  const { currentStep } = store.state.prospect
  const paymentRoutes = routes.filter(r => r.meta && r.meta.step && r.meta.step === 3)
  const isPaymentRoute = paymentRoutes.some(r => r.name === to.name)

  if (
    to.name === 'ConfirmedRegistration' ||
    to.name === 'RegistrationDenied' ||
    to.name === 'RegistrationRefused'
  ) {
    return next()
  }

  if (to.name !== 'UserRegistration' && currentStep === 1) {
    return next({ name: 'UserRegistration' })
  }

  if (to.name !== 'CompanyRegistration' && currentStep === 2) {
    return next({ name: 'CompanyRegistration' })
  }

  if (!isPaymentRoute && currentStep === 3) {
    return next({ name: 'PaymentMethod' })
  }

  if (to.name !== 'RegistrationSubmitted' && currentStep === 4) {
    return next({ name: 'RegistrationSubmitted' })
  }

  return next()
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})

router.beforeEach(navigationGuards)

export default router
